<h3 class="mb-0 p-3 align-self-center" data-cy="pageTitle">Support</h3>
<div class="m-4 mt-2" style="max-width: 38rem">
  <div class="d-flex align-items-center gap-3 mb-3">
    <i class="fa-solid fa-phone" style="font-size: 1.8rem"></i>
    <a href="tel:+1-833-613-2333">(833) 613-2333</a>
  </div>
  <div class="d-flex align-items-center gap-3">
    <i class="fa-solid fa-envelope" style="font-size: 1.8rem"></i>
    <a class="pb-1" href="mailto:support@brightscrip.com">support&#64;brightscrip.com</a>
  </div>
</div>
