import { Injectable } from '@angular/core';
import { LookupsService } from '@services/lookups.service';
import { BrightViewItemWithAccess, PageConfig, View } from '@shared/components/brightview/brightview-types';
import { UserRole } from '@shared/models/user-role.model';
import { IUser } from '@shared/models/user.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const CURRENT_DATAVIEW_VERSION = '3.0.0';
const DATAVIEW_SETTINGS_KEY = 'brightview';
const DEFAULT_MAP_CONFIG = {
  displayedInfo: {
    basicInfo: true,
    enrollments: true,
    scorecard: {
      INCY: false,
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class BrightViewConfigService<T> {
  constructor(private lookupsService: LookupsService) {}

  getBrightViewPageConfig(page: string, currentUser: IUser): Observable<PageConfig.PageConfig<T>> {
    return forkJoin({
      userViewDefinitions: this.lookupsService.getSettings<PageConfig.Schema<T>>(
        `user-username:${currentUser.username}`,
        `brightview_${page}_views`,
        'local',
        {
          subject: `user-username:${currentUser.username}`,
          attribute: `dataview_${page}_views`,
        },
      ),
      defaultViewDefinitons: this.lookupsService
        .getSettings<Array<View.DefaultView<T>>>(DATAVIEW_SETTINGS_KEY, `${page}_defaultViews`, 'local')
        .pipe(map(result => (result ?? []).filter(defaultView => this.userHasAccessToItem(defaultView, currentUser)))),
    }).pipe(
      map(results => {
        return {
          views: [
            ...this.mapViews(results.defaultViewDefinitons, results.userViewDefinitions?.pinnedViewIds ?? []),
            ...this.mapCustomViews(results.userViewDefinitions?.views ?? [], results.userViewDefinitions?.pinnedViewIds ?? []),
          ],
          pinnedViewIds: results.userViewDefinitions?.pinnedViewIds ?? [],
          configVersion: results.userViewDefinitions?.configVersion,
        };
      }),
    );
  }

  setDataViewPageConfig(page: string, currentUser: IUser, config: PageConfig.PageConfig<T>): Observable<Object | undefined> {
    return this.lookupsService.setSettings<PageConfig.Schema<T>>(
      `user-username:${currentUser.username}`,
      `brightview_${page}_views`,
      this.simplifyConfigForSaving(config),
      'local',
    );
  }

  private simplifyConfigForSaving(config: PageConfig.PageConfig<T>): PageConfig.Schema<T> {
    const simplifiedViews = config.views.map(view => {
      return {
        id: view.id,
        label: view.label,
        value: view.value,
      };
    });
    return {
      configVersion: CURRENT_DATAVIEW_VERSION,
      pinnedViewIds: config.pinnedViewIds,
      views: simplifiedViews,
    };
  }

  private userHasAccessToItem(item: BrightViewItemWithAccess, currentUser?: IUser) {
    const roleHasAccess =
      (!item.roleWhitelist || (currentUser?.role && item.roleWhitelist.includes(currentUser?.role as UserRole))) &&
      (!item.roleBlacklist || (currentUser?.role && !item.roleBlacklist.includes(currentUser?.role as UserRole)));
    const userHasRelevantTenantsAccess =
      !item.forTenantsWhitelist ||
      [UserRole.Administrator, 'FinanceAdmin'].includes(currentUser?.role as UserRole) ||
      (currentUser?.tenants && item.forTenantsWhitelist.every(tenant => currentUser?.tenants?.includes(tenant)));

    return roleHasAccess && userHasRelevantTenantsAccess;
  }

  private mapCustomViews<T>(views: Array<View.Schema<T>>, pinnedViewIds: string[]): Array<View.UserView<T>> {
    return this.mapViews(views, pinnedViewIds).map(view => ({ ...view, isCustom: true }) as View.UserView<T>);
  }

  private mapViews<T>(views: Array<View.Schema<T>>, pinnedViewIds: string[]): Array<View.UserView<T> | View.DefaultView<T>> {
    const mappedViews = [];
    for (const view of views) {
      const isPinned = view.isPinned || pinnedViewIds.includes(view.id);
      mappedViews.push({
        ...view,
        mapConfig: 'mapConfig' in view ? view.mapConfig : DEFAULT_MAP_CONFIG,
        isPinned,
      });
    }
    return mappedViews;
  }
}
